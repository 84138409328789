
.contact-form-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding: 50px 100px; */
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}

.form-container {
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0;
}

.form-header {
  /* text-align: center; */
  margin-bottom: 30px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.captcha-container {
  margin: 20px 0;
}

.button-container {
  display: flex;
  justify-content: center;
}

.captcha-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.captcha-wrap .captcha-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.captcha-wrap .captcha-overlay .captcha-body {
  position: relative;
  z-index: 1;
}

.captcha-wrap .captcha-body {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 350px;
  background: #fff;
  border-radius: 10px;
  padding: 5px;
}

.captcha-wrap .captcha-body .captcha-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.captcha-wrap .captcha-body .captcha-images img {
  width: 100%;
  height: 112px;
  object-fit: cover;
  transition: all 0.4s ease;
}

.captcha-wrap .captcha-body .captcha-title {
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #000;
}

.captcha-wrap .captcha-body .captcha-images .images:hover img {
  transform: scale(1.1);
}

.captcha-wrap .captcha-body .captcha-images .images {
  position: relative;
  height: 112px;
  overflow: hidden;
  border: 5px solid #fff;
}

.captcha-wrap .captcha-body .captcha-button button {
  background: purple;
  color: #fff;
  text-transform: capitalize;
  display: inline-block;
  padding: 5px 20px;
}

.captcha-wrap .captcha-body .captcha-button {
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: flex-end;
  gap: 15px;
}

.captcha-wrap .captcha-body .captcha-images .images.active {
  border-color: #32cd32;
}

.captcha-wrap .captcha-body .captcha-title .icon {
  float: right;
}



#slider-boxes {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px; /* Fixed width for the slides */
  height: 500px; /* Fixed height for the slides */
}

.slick-slide div {
  margin: 10px;
}

.slick-arrow:before {
  color: #0131B9;
  font-size: 30px; /* Corrected from font-style to font-size */
}

/* Adjust arrows for smaller screens */
@media screen and (max-width: 768px) {
  .slick-prev {
    top: calc(50% - 20px); /* Adjust the top position for the previous arrow */
    left: 10px; /* Adjust the left position for the previous arrow */
    font-size: 20px; /* Decrease font size of arrows */
    z-index: 9999 !important;
  }

  .slick-next {
    top: calc(50% - 20px); /* Adjust the top position for the next arrow */
    right: 10px; /* Adjust the right position for the next arrow */
    font-size: 20px; /* Decrease font size of arrows */
    z-index: 9999 !important;
  }
}

@media screen and (max-width: 480px) {
  .slick-prev{
    display: none !important;
  }
  .slick-next {
    display: none !important; /* Hide arrows on smaller screens */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Outfit:wght@100..900&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    padding: 0;
    margin: 0;
    font-family: "Poppins";
}

.button{
    background-color: #0131B9;
    border-radius: 8px;
    padding: 12px 20px;
    color: white;
}
.button:hover{
    background-color: #033EE5;
}
.homes {
  position: relative;
  overflow: hidden;
}

.homes::before {
  content: "";
  position: absolute;
  inset: 0;
  background: black;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.homes:hover::before {
  opacity: 0.7;
}

.homes .content {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.homes:hover .content {
  opacity: 1;
}

.expanded {
  transition: transform 0.8s ease-in-out;
  transform: scale(1.05);
}

.expanded-content {
  opacity: 1;
  transition: transform 0.8s ease-in-out, cursor 0.8s ease-in;
  cursor: pointer;
}

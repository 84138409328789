@media screen and (min-width: 1024px) {
  .background {
    background-image: url("./line.svg");
    background-repeat: no-repeat;
    background-position: center center;

  }
}
@media screen and (min-width: 320px) {
  #background-image {
    background-image: none;
  }
}
